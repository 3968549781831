import { Component, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { sign } from 'tweetnacl';
import bs58, { decode } from 'bs58';

const ConnectButton = styled(WalletDialogButton)``; // add your styles here
const SignButton = styled(Button)``; // add your styled here
const CloseButton = styled(Button)``;
const BuyButton = styled(Button)``;
const MintContainer = styled.div`height:100vh;display:flex;justify-content:center;align-items:center;flex-direction:column`;

export interface OwnerVerificationProps {
    // connection: anchor.web3.Connection,
}

const fromHexString = (hexString: any) =>
    new Uint8Array(hexString.match(/.{1,2}/g).map((byte: any) => parseInt(byte, 16)));

const OwnerVerification = (props: OwnerVerificationProps) => {

    const wallet = useAnchorWallet();
    const { publicKey, signMessage } = useWallet();
    const [discordUser, setDiscordUser] = useState({ id: '', avatar: '', username: '' });
    const [isVerified, setIsVerified] = useState(false);
    const [isSigning, setIsSigning] = useState(false);
    const [isMissingTokens, setIsMissingTokens] = useState(false);

    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const onClose = async () => {
        window.location.replace('https://dsc.gg/metawaifus');
    }

    const onBuy = async () => {
        window.open('https://solsea.io/collection/619444b45d71eeac814ff573', '_blank')?.focus();
    }

    // How to sign and verify messages?
    // https://github.com/solana-labs/wallet-adapter/blob/master/FAQ.md#how-can-i-sign-and-verify-messages
    const onSign = async () => {

        try {

            setIsSigning(true);

            if (!wallet) {
                setIsSigning(false);
                return;
            }

            if (!publicKey) throw new Error('Wallet not connected!');
            if (!signMessage) throw new Error('Wallet does not support message signing!');

            const message = `To avoid NFT Right-Clickers, sign below to authenticate with Meta Waifus. Discord User ID: ${discordUser?.id}`;

            // Encode message as bytes
            const encodedMessage = new TextEncoder().encode(message);

            // Sign the bytes using the wallet
            const signature = await signMessage(encodedMessage);

            // Verify that the bytes were signed using the private key that matches the known public key
            if (!sign.detached.verify(encodedMessage, signature, publicKey.toBytes())) throw new Error('Invalid signature!');

            authRequest(
                bs58.encode(publicKey.toBytes()),
                bs58.encode(signature),
                discordUser?.id,
            )

        } catch (e) {
            console.log(e);
            setAlertState({
                open: true,
                message: "Verification failed!",
                severity: "error",
            })
            setIsSigning(false);
        }
    }

    useEffect(() => {
        const fragment = new URLSearchParams(window.location.hash.slice(1));
        const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

        if (accessToken && tokenType) {
            fetchUsers(accessToken, tokenType);
        }

    }, []);

    useEffect(() => {
        (async () => {
            if (wallet) {
                // Query Solscan

                // Query MWs API
            }
        })();
    }, [wallet]);

    const fetchUsers = (accessToken: String, tokenType: String) => {
        fetch('https://discord.com/api/users/@me', {
            headers: {
                authorization: `${tokenType} ${accessToken}`,
            }
        })
            .then(result => result.json())
            .then((response) => {
                console.log(response);
                setDiscordUser(response);
            })
            .catch(console.error);
    }

    const authRequest = (publicKey: String, signature: String, discordUserId: String) => {
        fetch(`${process.env.REACT_APP_API_HOST!}discord/ownership_verification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                publicKey,
                signature,
                discordUserId,
            })
        })
            .then(result => result.json())
            .then((response) => {
                console.log(response)
                setIsSigning(false);

                if (response === 'OK') {
                    setIsVerified(true);
                    setAlertState({
                        open: true,
                        message: "Congratulations! You are now a verified holder",
                        severity: "success",
                    });
                }
                else if (response === 'Error: No tokens found!') {
                    setIsMissingTokens(true);
                    setAlertState({
                        open: true,
                        message: response,
                        severity: "error",
                    });
                }
                else {
                    setAlertState({
                        open: true,
                        message: response,
                        severity: "error",
                    });
                }
            })
            .catch((e) => {
                console.error(e)
                setAlertState({
                    open: true,
                    message: "Verification failed!",
                    severity: "error",
                });
                setIsSigning(false);
            })
    }


    return <main>
        <MintContainer>
            <h1 style={{ fontSize: '2rem' }}>Meta Waifus</h1>
            <h2 style={{ marginTop: '0' }}>Hodler Verification 💎🙌</h2>
            <img style={{ borderRadius: '50%', margin: '20px 0px 5px' }} src={`https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`} />
            <div style={{ fontWeight: 'bold', color: 'white', fontSize: 18, marginBottom: 15 }}>{discordUser?.username}</div>
            {
                !wallet
                    ?
                    <ConnectButton>Connect Wallet</ConnectButton>
                    :
                    isVerified
                        ?
                        <CloseButton
                            onClick={onClose}
                            variant="contained"
                            style={{ backgroundColor: 'black', color: 'white' }}
                        >
                            Done! Return to Discord
                        </CloseButton>
                        :
                        isMissingTokens
                            ?
                            <BuyButton
                                onClick={onBuy}
                                variant="contained"
                                style={{ backgroundColor: 'Red', color: 'white' }}
                            >
                                You don't have any MWs. Get Some!
                            </BuyButton>
                            :
                            <SignButton
                                onClick={onSign}
                                variant="contained"
                                style={{ backgroundColor: '#4caf50', color: 'white' }}
                            >
                                {
                                    isSigning ? (
                                        <CircularProgress />
                                    )
                                        : "Verify Ownership"
                                }
                            </SignButton>
            }

            <div style={{ marginTop: 20 }}>
                <div>Step 1: Connect your Solana wallet.</div>
                <div>Step 2: Click on `Verify Ownership` to sign a message with your wallet.</div>
                <div>Step 3: `Approve` the Signature Request on your wallet.</div>
                <div>Step 4: Done! You're now a verified hodler!</div>
                <div>Note: You should own at least 1 Meta Waifu NFT in your wallet.</div>
                <div>Warning: Make sure you are on an official link (<b>verify.metawaifus.com</b>).</div>
                {/* <div> Stop immediately if you are on a different website or the process is requesting any amount of SOL funds.</div> */}
            </div>
            <div style={{ marginTop: 20, fontSize: 14, color: 'white', fontWeight: 'bold' }}></div>
        </MintContainer>
        <Snackbar
            open={alertState.open}
            autoHideDuration={6000}
            onClose={() => setAlertState({ ...alertState, open: false })}
        >
            <Alert
                onClose={() => setAlertState({ ...alertState, open: false })}
                severity={alertState.severity}
            >
                {alertState.message}
            </Alert>
        </Snackbar>
    </main>;
}

interface AlertState {
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error" | undefined;
}

export default OwnerVerification;